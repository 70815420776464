import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/Account/Authenticate',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/AuctionSignUp',
        method: 'post',
        data: {
            ...data,
            tenantId: process.env.REACT_APP_TENANTID
        },
    })
}

export async function apiMobileNoSignUp(data) {
    return ApiService.fetchData({
        url: '/MobileNoAuctionSignUp',
        method: 'post',
        data: {
            ...data,
            tenantId: process.env.REACT_APP_TENANTID
        },
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}

export async function apiGetProfile(data) {
    return ApiService.fetchData({
        url: '/services/app/BuyerProfile/GetProfile',
        method: 'post',
        data,
    })
}
