import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiGetProfile, apiMobileNoSignUp, apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data.success) {
                const { token, id, staffId } = resp.data.result
                dispatch(onSignInSuccess(token))
                const profileResp = await apiGetProfile({id: id});
                if (profileResp.data.result) {
                    const user = {
                        userName: profileResp.data.result.userName,
                        name: profileResp.data.result.name,
                        email: profileResp.data.result.contact.email,
                        contact: profileResp.data.result.contact
                    }
                    dispatch(
                        setUser(
                            user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                                userId: id,
                                staffId: staffId
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            } else {
                let message = resp.data.error.details
                if (message.includes('user name')) {
                    message = message.replace('user name', 'email')
                }

                return {
                    status: 'failed',
                    message: message,
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                return resp.data

                // const { token } = resp.data
                // dispatch(onSignInSuccess(token))
                // if (resp.data.user) {
                //     dispatch(
                //         setUser(
                //             resp.data.user || {
                //                 avatar: '',
                //                 userName: 'Anonymous',
                //                 authority: ['USER'],
                //                 email: '',
                //             }
                //         )
                //     )
                // }
                // const redirectUrl = query.get(REDIRECT_URL_KEY)
                // navigate(
                //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                // )
                // return {
                //     status: 'success',
                //     message: '',
                // }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const mobileNoSignUp = async (values) => {
        try {
            const resp = await apiMobileNoSignUp(values)
            if (resp.data) {
                return resp.data
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        mobileNoSignUp,
        signOut,
    }
}

export default useAuth
