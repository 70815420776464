import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

const onRequest = (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)

    config.headers["TenantId"] = process.env.REACT_APP_TENANTID

    let accessToken = persistData.auth.session.token

    if (!accessToken) {
        const { auth } = store.getState()
        accessToken = auth.session.token
    }

    if (accessToken) {
        config.headers[
            REQUEST_HEADER_AUTH_KEY
        ] = `${TOKEN_TYPE}${accessToken}`
    }

    return config
}

const onResponseError = (error) => {
    const { response } = error

    if (response && unauthorizedCode.includes(response.status)) {
        store.dispatch(onSignOutSuccess())
    }

    return Promise.reject(error)
}

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 600000,
    baseURL: `${process.env.REACT_APP_API_URL}${appConfig.apiPrefix}`,
})

BaseService.interceptors.request.use(
    onRequest,
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    onResponseError
)

export const ApiBaseService = axios.create({
    timeout: 600000,
    baseURL: `${process.env.REACT_APP_API_URL}${appConfig.appApiPrefix}`,
})

ApiBaseService.interceptors.request.use(
    onRequest,
    (error) => {
        return Promise.reject(error)
    }
)

ApiBaseService.interceptors.response.use(
    (response) => response,
    onResponseError
)

export const ApiDirectService = axios.create({
    timeout: 600000,
    baseURL: `${process.env.REACT_APP_API_URL}`,
})

ApiDirectService.interceptors.request.use(
    onRequest,
    (error) => {
        return Promise.reject(error)
    }
)

ApiDirectService.interceptors.response.use(
    (response) => response,
    onResponseError
)

export default BaseService
